define("simplefolio-ember/components/scroll-reveal", ["exports", "@glimmer/component", "scrollreveal"], function (_exports, _component, _scrollreveal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id={{@elementId}} {{did-insert this.initializeScrollReveal}} ...attributes>
  	{{yield}}
  </div>
  */
  {
    id: "8qkHHLBl",
    block: "{\"symbols\":[\"@elementId\",\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"id\",[23,1,[]]],[13,2],[3,\"did-insert\",[[23,0,[\"initializeScrollReveal\"]]]],[8],[0,\"\\n\\t\"],[14,3],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "simplefolio-ember/components/scroll-reveal.hbs"
    }
  });

  const optionProps = ['delay', 'distance', 'duration', 'easing', 'interval', 'opacity', 'origin', 'rotate', 'scale', 'cleanup', 'container', 'desktop', 'mobile', 'reset', 'useDelay', 'viewFactor', 'viewOffset', 'afterReset', 'afterReveal', 'beforeReset', 'beforeReveal'];
  let XScrollReveal = (_class = (_temp = class XScrollReveal extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "options", _descriptor, this);

      _initializerDefineProperty(this, "rotateX", _descriptor2, this);

      _initializerDefineProperty(this, "rotateY", _descriptor3, this);

      _initializerDefineProperty(this, "rotateZ", _descriptor4, this);

      _initializerDefineProperty(this, "viewOffsetTop", _descriptor5, this);

      _initializerDefineProperty(this, "viewOffsetRight", _descriptor6, this);

      _initializerDefineProperty(this, "viewOffsetBottom", _descriptor7, this);

      _initializerDefineProperty(this, "viewOffsetLeft", _descriptor8, this);

      this.options = {};

      this._setDefaults();

      (0, _scrollreveal.default)({
        reset: false
      });
    }

    initializeScrollReveal(element) {
      (0, _scrollreveal.default)().reveal(element, this.options);
    }

    get delay() {
      return this.args.delay || 0;
    }

    get distance() {
      return this.args.distance || '0px';
    }

    get duration() {
      return this.args.duration || 600;
    }

    get easing() {
      return this.args.easing || 'cubic-bezier(0.5, 0, 0, 1)';
    }

    get interval() {
      return this.args.interval || 0;
    }

    get opacity() {
      return this.args.opacity || 0;
    }

    get origin() {
      return this.args.origin || 'bottom';
    }

    get rotateX() {
      return this.args.rotateX || 0;
    }

    get rotateY() {
      return this.args.rotateY || 0;
    }

    get rotateZ() {
      return this.args.rotateZ || 0;
    }

    get scale() {
      return this.args.scale || 1;
    }

    get cleanup() {
      return this.args.cleanup || false;
    }

    get container() {
      return this.args.container || window.document.documentElement;
    }

    get desktop() {
      return this.args.desktop || true;
    }

    get mobile() {
      return this.args.mobile || true;
    }

    get reset() {
      return this.args.reset || false;
    }

    get useDelay() {
      return this.args.useDelay || 'always';
    }

    get viewFactor() {
      return this.args.viewFactor || 0.0;
    }

    get rotate() {
      return {
        x: this.rotateX,
        y: this.rotateY,
        z: this.rotateZ
      };
    }

    get viewOffset() {
      return {
        top: this.viewOffsetTop,
        right: this.viewOffsetRight,
        bottom: this.viewOffsetBottom,
        left: this.viewOffsetLeft
      };
    }

    afterReset() {}

    afterReveal() {}

    beforeReset() {}

    beforeReveal() {}

    _setDefaults() {
      optionProps.forEach(option => {
        this.options[option] = this[option];
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rotateX", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "rotateY", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rotateZ", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "viewOffsetTop", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "viewOffsetRight", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "viewOffsetBottom", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "viewOffsetLeft", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initializeScrollReveal", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "initializeScrollReveal"), _class.prototype)), _class);
  _exports.default = XScrollReveal;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XScrollReveal);
});