define("simplefolio-ember/components/tilt-element", ["exports", "@glimmer/component", "vanilla-tilt"], function (_exports, _component, _vanillaTilt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{did-insert this.initializeVanillaTilt}} data-tilt>
  	{{yield}}
  </div>
  */
  {
    id: "IQfzWsrO",
    block: "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",false],[12,\"data-tilt\",\"\"],[3,\"did-insert\",[[23,0,[\"initializeVanillaTilt\"]]]],[8],[0,\"\\n\\t\"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "simplefolio-ember/components/tilt-element.hbs"
    }
  });

  const optionProps = ['reverse', 'max', 'startX', 'startY', 'perspective', 'scale', 'speed', 'transition', 'axis', 'easing', 'glare', 'max-glare', 'glare-prerender', 'mouse-event-element', 'full-page-listening', 'gyroscope', 'gyroscopeMinAngleX', 'gyroscopeMaxAngleX', 'gyroscopeMinAngleY', 'gyroscopeMaxAngleY', 'gyroscopeSamples'];
  let TiltElement = (_class = (_temp = class TiltElement extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "options", _descriptor, this);

      this.options = {};

      this._setDefaults();
    }

    initializeVanillaTilt(element) {
      _vanillaTilt.default.init(element, this.options);
    }

    get reverse() {
      return this.args.reverse || false;
    }

    get max() {
      return this.args.max || 35;
    }

    get startX() {
      return this.args.startX || 0;
    }

    get startY() {
      return this.args.startY || 0;
    }

    get perspective() {
      return this.args.perspective || 1000;
    }

    get scale() {
      return this.args.scale || 1;
    }

    get speed() {
      return this.args.speed || 300;
    }

    get transition() {
      return this.args.transition || true;
    }

    get axis() {
      return this.args.axis || null;
    }

    get reset() {
      return this.args.reset || true;
    }

    get easing() {
      return this.args.easing || "cubic-bezier(.03,.98,.52,.99)";
    }

    get glare() {
      return this.args.glare || false;
    }

    get "max-glare"() {
      return this.args["max-glare"] || 1;
    }

    get "glare-prerender"() {
      return this.args["glare-prerender"] || false;
    }

    get "mouse-event-element"() {
      return this.args["mouse-event-element"] || null;
    }

    get "full-page-listening"() {
      return this.args["full-page-listening"] || false;
    }

    get gyroscope() {
      return this.args.gyroscope || true;
    }

    get gyroscopeMinAngleX() {
      return this.args.gyroscopeMinAngleX || -45;
    }

    get gyroscopeMaxAngleX() {
      return this.args.gyroscopeMaxAngleX || 45;
    }

    get gyroscopeMinAngleY() {
      return this.args.gyroscopeMinAngleY || -45;
    }

    get gyroscopeMaxAngleY() {
      return this.args.gyroscopeMaxAngleY || 45;
    }

    get gyroscopeSamples() {
      return this.args.gyroscopeSamples || 10;
    }

    _setDefaults() {
      optionProps.forEach(option => {
        this.options[option] = this[option];
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "options", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initializeVanillaTilt", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "initializeVanillaTilt"), _class.prototype)), _class);
  _exports.default = TiltElement;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TiltElement);
});