define("simplefolio-ember/modifiers/jumpscroll", ["exports", "ember-modifier", "jump.js"], function (_exports, _emberModifier, _jump) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function jumpscroll(element) {
    const handler = e => {
      e.preventDefault();
      (0, _jump.default)(element.getAttribute('href'));
    };

    element.addEventListener('click', handler);
    return () => {
      element.removeEventListener('click', handler);
    };
  });

  _exports.default = _default;
});