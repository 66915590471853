define("simplefolio-ember/templates/components/etw/module-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "89a5NrtG",
    "block": "{\"symbols\":[\"moduleStyle\",\"snippet\"],\"statements\":[[7,\"section\",true],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"etw-pt-8 etw-mb-6 etw-text-lg etw-font-bold\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"class\",\"etw-list-reset etw-leading-normal\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"codeSnippets\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[8],[7,\"code\",true],[8],[1,[23,2,[]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"etw-mt-8 etw-flex etw-flex-wrap\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"moduleStyles\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"etw/module-style-example\",null,[[\"moduleStyle\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "simplefolio-ember/templates/components/etw/module-section.hbs"
    }
  });

  _exports.default = _default;
});