define("simplefolio-ember/utils/data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.socials = _exports.oldprojects = _exports.projects = _exports.embeds = _exports.videoprojects = void 0;
  const videoprojects = [{
    name: 'Robot Rampage',
    description: [`This game was created by a team 2 programmers and 4 artists in the space of 5 months for our final university project. Robot Rampage is a first person, sci-fi, wave based, shooter with elements of survival, and competitive multiplayer. `],
    url: 'https://musical-phenix.itch.io/robot-rampage',
    repo: 'https://github.com/Regilio-Henry/RobotRampage13',
    thumbnail: {
      videoUrl: 'https://www.youtube.com/embed/uKXOBBwuA68'
    }
  }];
  _exports.videoprojects = videoprojects;
  const embeds = [{
    name: 'Verlet integration in Pico8',
    description: [`project I implemented Verlet
physics integration within the Pico8 
Fantasy console using Lua. The aim of 
this project was to learn more about the implementation of game physics.
(Controls: Movement - [Arrows], place ball [X], drop ball [Z])`],
    repo: 'https://www.lexaloffle.com/bbs/?tid=33111',
    thumbnail: {
      videoUrl: 'https://www.lexaloffle.com/bbs/widget.php?pid=dihobzifo'
    }
  }];
  _exports.embeds = embeds;
  const projects = [{
    name: 'Wave Bird',
    description: [`This was a project I worked on with a friend for the global game jam.
The theme of this jam was waves. Had a good time working on this and happy with the result
the two of us achieved in such a short time period.`],
    url: 'https://globalgamejam.org/2018/games/wave-bird',
    repo: 'https://github.com/regygamer/WaveBird',
    thumbnail: {
      image: 'assets/images/wb.gif',
      alt: 'project banner'
    }
  }, {
    name: 'Software 3D rendering engine',
    description: [`In this project I was tasked to write a 3D rendering engine
in C++ using win32. This had me get familiar
with the maths involved with 3D rendering 
such as Vectors and Matrices and get a better
understanding how the graphics pipeline
works.`],
    repo: 'https://github.com/regygamer/Software-Rendering-Engine',
    thumbnail: {
      image: 'assets/images/software%20render%20.gif',
      alt: 'project banner'
    }
  }, {
    name: 'Mechdragon',
    description: [`This game is a simple 2D spaceshooter where the player controls a dragon.
The aim of this project was to familiarize myself with game programming paterns such
as the gameloop, a component based system and game states and further more a system that handles sprite animation.`],
    repo: 'https://github.com/regygamer/Mechdragon',
    thumbnail: {
      image: 'assets/images/dragon%20game.gif',
      alt: 'project banner'
    }
  }, {
    name: 'Legacy of Dalv - Blood Tithe',
    description: [`This was a team project done for university. A topdown dungeon crawler in which you complete various
challenges. In this project I was wrote the code for player stat management, the Challenge system and A* pathfinding.`],
    url: 'https://3d20.itch.io/legacy-of-dalv-blood-tithe',
    repo: 'https://github.com/Regilio-Henry/Blood-money-public-',
    thumbnail: {
      image: 'assets/images/tproj.gif',
      alt: 'project banner'
    }
  }];
  _exports.projects = projects;
  const oldprojects = [{
    name: 'Robot Rampage',
    description: [`This game was created by a team 2 programmers and 4 artists in the space of 5 months for our final university project. Robot Rampage is a first person, sci-fi, wave based, shooter with elements of survival, and competitive multiplayer. `],
    url: 'https://musical-phenix.itch.io/robot-rampage',
    repo: 'https://github.com/Regilio-Henry/RobotRampage13',
    apis: {
      language: 'Test1',
      api: 'Test2',
      framework: 'Test3',
      extra: 'Test4'
    }
  }, {
    name: 'Robot Rampage',
    description: [`This game was created by a team 2 programmers and 4 artists in the space of 5 months for our final university project. Robot Rampage is a first person, sci-fi, wave based, shooter with elements of survival, and competitive multiplayer. `],
    url: 'https://musical-phenix.itch.io/robot-rampage',
    repo: 'https://github.com/Regilio-Henry/RobotRampage13',
    apis: {
      language: 'Test1',
      api: 'Test2',
      framework: 'Test3',
      extra: 'Test4'
    }
  }];
  _exports.oldprojects = oldprojects;
  const socials = [{
    name: 'twitter',
    url: 'https://twitter.com/SignificantByte'
  }, {
    name: 'linkedin',
    url: 'https://www.linkedin.com/in/regilio-henry-a17734130/'
  }, {
    name: 'github',
    url: 'https://github.com/Regilio-Henry'
  }];
  _exports.socials = socials;
});